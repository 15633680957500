<template>
	<v-flex xs12>
		<v-layout dense>
			<v-flex xs12 md8 lg9>
				<pui-select
					:id="`resolucion-condicion-idconcod`"
					attach="idconcod"
					:label="$t('resolucion.idconcod')"
					toplabel
					clearable
					:required="isEmpty(model.connocod) && condicionesOblig"
					:disabled="formDisabled"
					v-model="model"
					modelName="vlupmrescondicion"
					:itemsToSelect="itemsToSelectCondCod"
					:modelFormMapping="{ idconcod: 'idconcod' }"
					itemValue="idconcod"
					:itemText="item => `${item.codconresedi} - ${item.name}`"
					:order="{ name: 'asc' }"
					:filterId="`codpue-resolucion-condicion`"
					:fixedFilter="fixedFilterVigencia"
					reactive
				></pui-select>
			</v-flex>
		</v-layout>
		<v-layout dense>
			<v-flex xs12>
				<pui-text-field
					:id="`resolucion-condicion-connocod`"
					v-model="model.connocod"
					:label="$t('resolucion.connocod')"
					:disabled="formDisabled"
					:required="isEmpty(model.idconcod) && condicionesOblig"
					toplabel
					maxlength="350"
				></pui-text-field>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
export default {
	name: 'resolucioncondicionquickedit-form',
	data() {
		return {
			condicionesOblig:false
		};

	},
	created(){
		if (!this.model.codpue || this.model.codpue==null) {
			this.model.codpue=this.parentModel.codpue;
		}
	},
	watch: {
		model(newVal) {
			if (!newVal.codpue || newVal.codpue==null) {
				newVal.codpue=this.parentModel.codpue;
				this.$set(this.model,'codpue',this.parentModel.codpue);
			}
		},
		'model.idconcod'() {
			if (!this.isEmpty(this.model.idconcod)) {
				this.model.connocod = null;
			}
		},
		'model.connocod'() {
			if (!this.isEmpty(this.model.connocod)) {
				this.model.idconcod = null;
			}
		}
	},
	methods:{
		isEmpty(...values) {
			return values.some(
				value =>
				value === undefined ||
				value === null ||
				(typeof value === 'string' && value.trim() === '') ||
				(Array.isArray(value) && value.length === 0)
			);
		}
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		parentModel: {
			type: Object,
			required: true
		}
	},
	computed: {
		fixedFilterVigencia(){
			return {
				groups: [{
					groups: [],
					groupOp: 'or',
					rules: [
						{ field: 'fecfinvigencia', op: 'ge', data: this.parentModel.fecinicioaut },
						{ field: 'fecfinvigencia', op: 'nu'}
					]
				},
				{
					groups: [],
					groupOp: 'or',
					rules: [
						{ field: 'codpue', op: 'ge', data: this.parentModel.codpue },
						{ field: 'codpue', op: 'nu'}
					]
				}
				],
				groupOp: 'and',
				rules: [
					{ field: 'fecinivigencia', op: 'le', data: this.parentModel.fecinicioaut }
				]
			};
		},
		itemsToSelectCondCod(){
			return [{ idconcod: this.model.idconcod }]
		}
	},
	//:filterMap="{ codpue: model.codpue }"
	mounted() {
		this.$puiEvents.$on(`onPuiSelect_selectedItems-idtipres-resolucion`, newVal => {
            var lupamodel = newVal.model;
            if (lupamodel || lupamodel!=null) {

				this.condicionesOblig=false;
				if(lupamodel.codresedi=='ZED' || lupamodel.codresedi=='ZEN'){
					this.condicionesOblig=true;
				}
			}
        });

		/*this.$puiEvents.$on(`onPui-quickEditionForm-resolucioncondicion-validate`,() => {
			var revalidate = !this.condicionesOblig;
			this.condicionesOblig=true;
			if (revalidate) this.$puiEvents.$emit(`onPui-quickEditionForm-resolucioncondicion-validate`);
		});*/

	},
	destroyed(){
		//this.$puiEvents.$off(`onPuiSelect_selectedItems-idtipres-resolucion`);
	}
};
</script>
